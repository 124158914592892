<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:title>
        <h1>{{ $t("notFound.title") }}</h1>
      </template>
    </AppHeader>

    <main class="container d-flex flex-col gap-2 py-5 px-2 text-center">
      <h2 class="red fs-5 fw-black" aria-hidden="true">404</h2>
      <h3>{{ $t("notFound.message") }}</h3>
      <p class="light-grey">{{ $t("notFound.reason") }}</p>
      <p class="py-1">
        <RouterLink to="/">
          {{ $t("notFound.redirect-link") }}
        </RouterLink>
      </p>
    </main>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import { getParams } from "@/mixins/platform-params.js";

export default {
  name: "NotFound",
  mixins: [getParams],
  components: { AppHeader }
};
</script>
